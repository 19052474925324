import { template as template_7d2cc804373a4cdcae25f1c6ca71edcf } from "@ember/template-compiler";
const FKLabel = template_7d2cc804373a4cdcae25f1c6ca71edcf(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
