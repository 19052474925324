import { template as template_0d3b7cb628084dc5b6e7612c9c6255be } from "@ember/template-compiler";
const FKText = template_0d3b7cb628084dc5b6e7612c9c6255be(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
