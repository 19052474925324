import { template as template_3d266e9eddd34f9ebfc788151ea09ea4 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_3d266e9eddd34f9ebfc788151ea09ea4(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
